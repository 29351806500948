function numbersOnly(txt: string) {
  return txt.replace(/\D/g, "");
}

function realToDecimal(num: string) {
  if (num && num !== "") {
    return num.replace("R$ ", "").replace(".", "").replace(",", ".");
  }
  return null;
}

export { numbersOnly, realToDecimal };
