import BaseService from "./base.service";
import moment from "moment";

class ReportService extends BaseService {
  constructor() {
    super(`v1/report`);
  }

  getReport = async (options?: any) => {
    const optionsUrl = options
      ? encodeURIComponent(JSON.stringify(options))
      : "";
    return this.request("get", `?options=${optionsUrl}`);
  };

  downloadCsvReport = async (options: any) => {
    const yesterday = moment().add(-1, "days").format("DD/MM/YY");
    const data = await this.request("POST", "download_csv_report", { options });
    const blob = new Blob([data], { type: "text/csv; charset=utf-8" });
    const url = URL.createObjectURL(blob);

    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", `balaroti-banckero-${yesterday}.csv`);
    document.body.appendChild(link);
    link.click();
    link.parentNode?.removeChild(link);
  };
}

export default new ReportService();
