import * as React from "react";
import { useTheme } from "@mui/material/styles";
import {
  LineChart,
  XAxis,
  YAxis,
  Label,
  ResponsiveContainer,
  Line,
} from "recharts";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import { readableDate } from "../helpers/formatters";

export default function Chart(props) {
  const theme = useTheme();

  const { data } = props;
  const fixedData = data.map((i) => {
    return {
      created_at: readableDate(i.created_at),
      amount: parseFloat(i.amount),
    };
  });
  return (
    <React.Fragment>
      <Grid item xs={12} md={8} lg={9}>
        <Paper
          sx={{
            p: 2,
            display: "flex",
            flexDirection: "column",
            height: 300,
          }}
        >
          {/* <Title>Hoje</Title>*/}
          <ResponsiveContainer>
            <LineChart
              data={fixedData}
              defaultShowTooltip={true}
              margin={{
                top: 16,
                right: 16,
                bottom: 5,
                left: 24,
              }}
            >
              <XAxis
                dataKey="created_at"
                stroke={theme.palette.text.secondary}
                style={theme.typography.body2}
              />
              <YAxis
                stroke={theme.palette.text.secondary}
                style={theme.typography.body2}
              >
                <Label
                  angle={270}
                  position="left"
                  style={{
                    textAnchor: "middle",
                    fill: theme.palette.text.primary,
                    ...theme.typography.body1,
                  }}
                >
                  Pagamentos (R$)
                </Label>
              </YAxis>
              <Line
                isAnimationActive={false}
                type="linear"
                dataKey="amount"
                stroke={theme.palette.primary.main}
                dot={true}
              />
            </LineChart>
          </ResponsiveContainer>
        </Paper>
      </Grid>
    </React.Fragment>
  );
}

Chart.propTypes = {
  data: PropTypes.any,
};
