import * as React from "react";
import { useEffect, useState } from "react";
import withMenu from "../hocs/withMenu";
import Service from "../services/fgts.service";
import ReportService from "../services/report.service";
import PaymentItem, { PaymentPropType } from "./PaymentItem";
import Grid from "@mui/material/Grid";
import Title from "../components/Title";
import TableHead from "@mui/material/TableHead";
import Table from "@mui/material/Table";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import { styled } from "@mui/material/styles";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import moment from "moment";

import {
  Alert,
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";

import { InferProps } from "prop-types";
import Button from "@mui/material/Button";
import Toolbar from "@mui/material/Toolbar";
import Box from "@mui/material/Box";

const PaymentList = () => {
  const [response, setResponse] = useState([]);

  const [error, setError] = useState("");
  const clearError = () => setTimeout(() => setError(""), 5 * 1000);
  const [loading, setLoading] = useState(false);

  interface Filter {
    filter: {
      status?: string;
      createdAt?: moment.Moment;
    };
  }

  const [options, setOptions] = useState({
    filter: { status: "paid", createdAt: moment() },
  } as Filter);

  const filterStatus = (event) => {
    const newFilter = options.filter;
    const status = event.target.value;
    if (status === "") {
      delete newFilter.status;
    } else {
      newFilter["status"] = status;
    }
    setOptions({
      ...options,
      filter: newFilter,
    });

    search();
  };

  const filterCreatedAt = (date) => {
    const newFilter = options.filter;
    newFilter["createdAt"] = date;
    setOptions({
      ...options,
      filter: newFilter,
    });

    search();
  };

  const search = () => {
    setLoading(true);
    Service.getPayments(options)
      .then((resp) => {
        setResponse(resp);
      })
      .catch((e) => {
        console.log(e);
        if (e.response.data) {
          setError(e.response.data.message);
        } else {
          setError(
            "Ocorreu um erro ao listar os pagamentos. Tente novamente mais tarde!"
          );
        }
        clearError();
      })
      .finally(() => setLoading(false));
  };
  useEffect(() => {
    search();
  }, []);

  type PaymentType = InferProps<typeof PaymentPropType>;
  const payments = response.map((payment: PaymentType) => (
    <PaymentItem key={payment.id} payment={payment} />
  ));

  const downloadCSVReport = async () => {
    await ReportService.downloadCsvReport(options);
  };

  return (
    <Grid container direction="column">
      <Grid item sx={{ mt: 2, mb: 2 }}>
        <Toolbar>
          <Box sx={{ flex: "1 1 100%" }}>
            <Title>Pagamentos</Title>
          </Box>

          <Box sx={{ m: 1, width: "100%", maxWidth: "200px" }}>
            <FormControl style={{ maxWidth: "200px", width: "100%" }}>
              <InputLabel id="status-select-label">Status</InputLabel>
              <Select
                id="status-select"
                label="Status"
                labelId="status-select-label"
                onChange={(event) => filterStatus(event)}
                value={options.filter?.status || ""}
              >
                <MenuItem value="">Todos</MenuItem>
                <MenuItem value="paid">Pago</MenuItem>
                <MenuItem value="pending">Pendente</MenuItem>
                <MenuItem value="created">Aguardando</MenuItem>
                <MenuItem value="canceled">Cancelado</MenuItem>
              </Select>
            </FormControl>
          </Box>

          <Box sx={{ m: 1 }}>
            <MobileDatePicker
              label="Data pagamento"
              inputFormat="DD/MM/YYYY"
              value={options.filter?.createdAt}
              onChange={(event) => filterCreatedAt(event)}
              renderInput={(params) => <TextField {...params} />}
            />
          </Box>
        </Toolbar>
      </Grid>
      {error && <Alert severity="error">{error}</Alert>}
      {loading && (
        <CircularProgress
          style={{ padding: "10px" }}
          size="4rem"
          thickness={5}
        />
      )}

      <Grid item sx={{ mt: 3 }}>
        <StyledTable>
          <StyledHead>
            <TableRow>
              <TableCell>Data</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>CPF</TableCell>
              <TableCell>Nome</TableCell>
              <TableCell>Contrato</TableCell>
              <TableCell align="right">Valor da Compra</TableCell>
            </TableRow>
          </StyledHead>
          <StyledTableBody>{payments}</StyledTableBody>
        </StyledTable>
      </Grid>

      <Grid item sx={{ mt: 3 }}>
        <Button onClick={() => downloadCSVReport()} variant="outlined">
          Download CSV
        </Button>
      </Grid>
    </Grid>
  );
};

const StyledHead = styled(TableHead)(({ theme }) => ({
  background: "#ebebeb",
}));

const StyledTable = styled(Table)(({ theme }) => ({
  border: "2px solid #f0f0f2",
  borderRadius: "5px",
  padding: "10px",
}));

const StyledTableBody = styled(TableBody)(({ theme }) => ({
  padding: "25px",
  marginTop: "15px",
  background: "rgba(255,255,255,0.1)",
  "&>:nth-child(even)": {
    background: "#f0f0f2",
  },
}));

export default withMenu(PaymentList, "Lista de Pagamentos");
