import { createTheme } from "@mui/material/styles";

const BnkrTheme = createTheme({
  palette: {
    primary: {
      main: "#262e65",
    },
  },
});

export default BnkrTheme;
