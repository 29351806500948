import withMenu from "../hocs/withMenu";
import TextField from "@mui/material/TextField";
import InputMask from "react-input-mask";
import Button from "@mui/material/Button";
import { Alert, CircularProgress, Dialog, DialogContent } from "@mui/material";
import Box from "@mui/material/Box";
import * as React from "react";
import { useState } from "react";
import FGTSService from "../services/fgts.service";
import { maskToCurrency } from "../helpers/currencyMask";
import { useNavigate } from "react-router-dom";
import Typography from "@mui/material/Typography";

const IssuePayment = () => {
  const navigate = useNavigate();

  const [error, setError] = useState("");
  const clearError = () => setTimeout(() => setError(""), 5 * 1000);
  const [loading, setLoading] = useState(false);

  const [open, setDialogOpen] = useState(false);

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const data = new FormData(event.currentTarget);
    const phone = data.get("phone");
    const name = data.get("name");
    const cpf = data.get("cpf");
    const amount = data.get("amount");
    const product = data.get("product");

    if (phone && name && cpf && amount && product) {
      setLoading(true);
      FGTSService.issuePayment(
        phone.toString(),
        name.toString(),
        cpf.toString(),
        amount.toString(),
        product.toString()
      )
        .then(() => {
          setDialogOpen(true);
        })
        .catch((e) => {
          console.log(e);
          if (e.response.data) {
            setError(e.response.data.message);
          } else {
            setError(
              "Ocorreu um erro ao emitir pagamento. Tente novamente mais tarde!"
            );
          }
          clearError();
        })
        .finally(() => setLoading(false));
    } else {
      setError("Preencha todos os dados!");
      clearError();
    }
  };

  return (
    <Box
      component="form"
      onSubmit={handleSubmit}
      noValidate
      sx={{ mt: 1 }}
      maxWidth="450px"
    >
      <Dialog open={open} onClose={() => navigate("/pagamentos")}>
        <DialogContent>
          <Typography variant="body2">
            Link de Pagamento gerado com sucesso!
          </Typography>
        </DialogContent>
      </Dialog>
      <InputMask mask="999.999.999-99" name="cpf" fullWidth>
        <TextField margin="normal" required label="CPF" variant="outlined" />
      </InputMask>
      <TextField
        margin="normal"
        required
        fullWidth
        name="name"
        label="Nome"
        type="text"
        id="name"
        autoComplete="name"
      />
      <InputMask mask="(99) 99999-9999" name="phone" fullWidth>
        <TextField
          margin="normal"
          required
          label="Telefone"
          variant="outlined"
        />
      </InputMask>

      <Box sx={{ mt: 5 }}>
        <InputMask
          mask="R$ 9999999999" // Don't use dots and commas
          alwaysShowMask={false}
          beforeMaskedStateChange={maskToCurrency}
          name="amount"
          id="outlined-basic"
        >
          <TextField
            fullWidth
            label="Valor compra"
            variant="outlined"
            required
          />
        </InputMask>

        <TextField
          margin="normal"
          required
          fullWidth
          name="product"
          label="Produto"
          type="text"
          id="product"
        />
      </Box>
      <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>
        Gerar
      </Button>

      {loading && (
        <CircularProgress
          style={{ padding: "10px" }}
          size="4rem"
          thickness={5}
        />
      )}

      {error && <Alert severity="error">{error}</Alert>}
    </Box>
  );
};

export default withMenu(IssuePayment, "Gerar pagamento");
