import * as React from "react";

import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { ThemeProvider } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { Alert, CircularProgress } from "@mui/material";

import AuthService from "../services/auth.service";
import BnkrTheme from "../theme";
import Link from "@mui/material/Link";

const theme = BnkrTheme;
const logoBlue = `${process.env.PUBLIC_URL}/logo-blue.png`;

export default function SignIn() {
  const navigate = useNavigate();

  const [error, setError] = useState("");
  const clearError = () => setTimeout(() => setError(""), 5 * 1000);
  const [loading, setLoading] = useState(false);

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const email = data.get("email");
    const password = data.get("password");

    if (email && password) {
      setLoading(true);
      AuthService.login(email.toString(), password.toString())
        .then((data) => {
          console.log("DATA", data);
          navigate("/pagamentos");
        })
        .catch((e) => {
          if (e.response.data) {
            setError(e.response.data.message);
          } else {
            setError(
              "Ocorreu um erro ao realizar o login. Tente novamente mais tarde!"
            );
          }
          clearError();
        })
        .finally(() => setLoading(false));
    } else {
      setError("Email e Senha são obrigatórios");
      clearError();
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Box sx={{ m: 1 }}>
            <img src={logoBlue} width="300px" />
          </Box>
          <Typography component="h1" variant="h5">
            Dashboard
          </Typography>
          <Box
            component="form"
            onSubmit={handleSubmit}
            noValidate
            sx={{ mt: 1 }}
          >
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="E-mail"
              name="email"
              autoComplete="email"
              autoFocus
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Senha"
              type="password"
              id="password"
              autoComplete="current-password"
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Entrar
            </Button>

            {loading && (
              <CircularProgress
                style={{ padding: "10px" }}
                size="4rem"
                thickness={5}
              />
            )}

            {error && <Alert severity="error">{error}</Alert>}

            <Link
              href={"/cadastrar"}
              style={{
                textAlign: "center",
                margin: "20px auto",
                display: "block",
                width: "100%",
                maxWidth: "250px",
              }}
            >
              Cadastrar
            </Link>
          </Box>
        </Box>
      </Container>
    </ThemeProvider>
  );
}
