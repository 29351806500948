import * as React from "react";
import ListItemButton from "@mui/material/ListItemButton";
import { styled } from "@mui/material/styles";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import ListSubheader from "@mui/material/ListSubheader";
import AnalyticsIcon from "@mui/icons-material/Analytics";
import ViewListIcon from "@mui/icons-material/ViewList";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";

import { Link } from "react-router-dom";

const ListItemMenu = styled(ListItemButton)(({ theme }) => ({
  padding: 0,
  paddingLeft: 15,
}));

const LinkMenu = styled(Link)(({ theme }) => ({
  padding: "20px 30px",
  marginLeft: "-55px",
  width: "100%",
  textDecoration: "none",
  color: "inherit",
}));

export const mainListItems = (
  <>
    <ListSubheader component="div" inset>
      Pagamentos
    </ListSubheader>
    <ListItemMenu>
      <ListItemIcon>
        <AnalyticsIcon />
      </ListItemIcon>
      <LinkMenu to="/dashboard">
        <ListItemText primary="Relatórios" />
      </LinkMenu>
    </ListItemMenu>
    <ListItemMenu>
      <ListItemIcon>
        <ViewListIcon />
      </ListItemIcon>
      <LinkMenu to="/pagamentos">
        <ListItemText primary="Pagamentos" />
      </LinkMenu>
    </ListItemMenu>
    <ListItemMenu>
      <ListItemIcon>
        <AttachMoneyIcon />
      </ListItemIcon>
      <LinkMenu to="/emitir-pagamento">
        <ListItemText primary="Emitir Pagamento" />
      </LinkMenu>
    </ListItemMenu>
  </>
);

export const secondaryListItems = (
  <>
    <ListSubheader component="div" inset>
      Simulação
    </ListSubheader>
    <ListItemMenu>
      <ListItemIcon>
        <AnalyticsIcon />
      </ListItemIcon>
      <LinkMenu to="/simulacao-manual">
        <ListItemText primary="Simulação Manual" />
      </LinkMenu>
    </ListItemMenu>
    <ListItemMenu>
      <ListItemIcon>
        <AnalyticsIcon />
      </ListItemIcon>
      <LinkMenu to="/simulacao-integrada">
        <ListItemText primary="Simulação Integrada" />
      </LinkMenu>
    </ListItemMenu>
  </>
);
