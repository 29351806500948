import { realToDecimal } from "../helpers/numbers";
import BaseService from "./base.service";

// eslint-disable-next-line require-jsdoc
class FGTSService extends BaseService {
  constructor() {
    super(`v1/fgts`);
  }

  integratedSimulation = async (cpf: string, amount: string) => {
    return this.request("POST", "payment_simulation", {
      integrated: {
        cpf,
        amount: realToDecimal(amount),
      },
    });
  };

  issuePayment = async (
    phone: string,
    name: string,
    cpf: string,
    rawAmount: string,
    product: string
  ) => {
    const amount = realToDecimal(rawAmount);
    const code = Math.random().toString(16).slice(2, 10);
    return this.request("POST", "payment", {
      amount: amount,
      customer: {
        cpf: cpf,
        phone: phone,
        name: name,
      },
      items: [
        {
          name: product,
          code: `${code}`,
          id: `${code}`,
          price: amount,
          quantity: 1,
        },
      ],
    });
  };

  manualSimulation = async (balance: string, birthDate: string) => {
    return this.request("POST", "payment_simulation", {
      manual: {
        balance: realToDecimal(balance),
        birthDate,
      },
    });
  };

  getPayments = async (options: any) => {
    const optionsUrl = encodeURIComponent(JSON.stringify(options));
    return this.request("get", `payment?options=${optionsUrl}`);
  };
}

export default new FGTSService();
