import BaseService from "./base.service";

class AuthService extends BaseService {
  constructor() {
    super("v1/user");
  }

  login(username: string, password: string) {
    return this.request("POST", "login", {
      username,
      password,
    }).then((data) => {
      if (data.token) {
        localStorage.setItem("user", JSON.stringify(data));
      }
      return data;
    });
  }

  logout() {
    localStorage.removeItem("user");
  }

  register(name: string, username: string, password: string) {
    return this.request("POST", "signup", {
      name,
      username,
      password,
    }).then((data) => {
      if (data.token) {
        localStorage.setItem("user", JSON.stringify(data));
      }
      return data;
    });
  }

  getCurrentUser() {
    const userStr = localStorage.getItem("user");
    if (userStr) return JSON.parse(userStr);
    return null;
  }
}
export default new AuthService();
