import * as React from "react";
import { useState } from "react";
import withMenu from "../hocs/withMenu";
import Service from "../services/fgts.service";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import InputMask from "react-input-mask";
import SimulationResponse from "./SimulationResponse";
import { maskToCurrency } from "../helpers/currencyMask";
import { Alert, CircularProgress } from "@mui/material";

const IntegratedSimulation = () => {
  const [amount, setAmount] = useState("");
  const [cpf, setCpf] = useState("");
  const [response, setResponse] = useState();

  const [error, setError] = useState("");
  const clearError = () => setTimeout(() => setError(""), 5 * 1000);
  const [loading, setLoading] = useState(false);

  function calculateSimulation() {
    setLoading(true);
    Service.integratedSimulation(cpf, amount)
      .then((resp) => {
        setResponse(resp);
      })
      .catch((e) => {
        console.log(e);
        if (e.response.data) {
          setError(e.response.data.message);
        } else {
          setError("Ocorreu um erro ao simular. Tente novamente mais tarde!");
        }
        clearError();
      })
      .finally(() => setLoading(false));
  }

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={3}>
          <InputMask
            mask="999.999.999-99"
            onChange={(e) => {
              setCpf(e.target.value);
            }}
            value={cpf}
            label="CPF"
            variant="outlined"
            fullWidth
          >
            <TextField label="CPF" variant="outlined" required />
          </InputMask>
        </Grid>
        <Grid item xs={3}>
          <InputMask
            mask="R$ 9999999999" // Don't use dots and commas
            alwaysShowMask={false}
            beforeMaskedStateChange={maskToCurrency}
            value={amount}
            onChange={(e) => {
              setAmount(e.target.value);
            }}
            id="outlined-basic"
          >
            <TextField label="Valor desejado" variant="outlined" required />
          </InputMask>
        </Grid>
        <Grid item xs={12}>
          <Button onClick={() => calculateSimulation()} variant="outlined">
            SIMULAR
          </Button>
        </Grid>

        {loading && (
          <CircularProgress
            style={{ padding: "10px" }}
            size="4rem"
            thickness={5}
          />
        )}
        {error && <Alert severity="error">{error}</Alert>}
      </Grid>
      <SimulationResponse response={response} />
    </>
  );
};

export default withMenu(IntegratedSimulation, "Simulação Integrada");
