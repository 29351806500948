import * as React from "react";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";

import {
  formatCPF,
  readableDate,
  toReal,
  translateStatus,
} from "../helpers/formatters";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";

function PaymentItem(props) {
  const { payment } = props;

  const amount = toReal(payment.amount);
  const date = readableDate(payment.createdAt);
  const status = String(translateStatus(payment.status));
  const cpf = formatCPF(payment.customer.cpf);
  const statusElement =
    payment.status === "paid" ? <strong>{status}</strong> : status;

  return (
    <Row key={payment.id}>
      <TableCell>{date}</TableCell>
      <TableCell>{statusElement}</TableCell>
      <TableCell>{cpf}</TableCell>
      <TableCell>{payment.customer.name}</TableCell>
      <TableCell>{payment.contract?.code || "-"}</TableCell>
      <TableCell align="right">{amount}</TableCell>
    </Row>
  );
}

export const PaymentPropType = {
  id: PropTypes.string.isRequired,
  createdAt: PropTypes.string.isRequired,
  amount: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  customer: PropTypes.shape({
    name: PropTypes.string.isRequired,
    cpf: PropTypes.string.isRequired,
  }),
};

PaymentItem.propTypes = {
  payment: PaymentPropType,
};

const Row = styled(TableRow)(({ theme }) => ({
  padding: "25px",
}));

export default PaymentItem;
