import moment from "moment";

function toReal(num: number) {
  const test = parseFloat(String(num));
  return test.toLocaleString("pt-br", {
    style: "currency",
    minimumFractionDigits: 2,
    currency: "BRL",
  });
}

function readableDate(date: string) {
  return moment(date).format("DD/MM/YY HH:mm");
}

function translateStatus(status: string) {
  if (status === "created") {
    return "Aguardando";
  } else if (status === "pending") {
    return "Pendente";
  } else if (status === "canceled") {
    return "Cancelado";
  } else if (status === "paid") {
    return "Pago";
  }
}

function formatCPF(rawCpf) {
  const cpf = rawCpf.replace(/[^\d]/g, "");
  return cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
}

export { toReal, readableDate, translateStatus, formatCPF };
