import axios from "axios";
import authHeader from "./auth-header";

export default class BaseService {
  BASE_URL =
    window.location.hostname === "localhost"
      ? "http://localhost:9030"
      : "https://api.payment.banckero.com.br";
  protected API_URL: string;

  constructor(endUrl: string) {
    this.API_URL = `${this.BASE_URL}/${endUrl}`;
  }

  request = async (
    method: string,
    endUrl: string,
    data: any = undefined,
    extraHeaders = {}
  ) => {
    const response = await axios({
      method: method,
      url: `${this.API_URL}/${endUrl}`,
      data: data,
      headers: authHeader(extraHeaders),
    });
    return response.data;
  };
}
