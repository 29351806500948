import * as React from "react";
import Typography from "@mui/material/Typography";
import Title from "./Title";
import { toReal } from "../helpers/formatters";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";

export default function TotalPayments(props) {
  const { title, amount, money = true } = props;
  return (
    <React.Fragment>
      <Grid item xs={12} md={4} lg={3} mt={4}>
        <Paper
          sx={{
            p: 2,
            display: "flex",
            flexDirection: "column",
            height: 150,
          }}
        >
          <Title>{title}</Title>
          <Typography component="p" variant="h4">
            {money ? toReal(amount) : amount}
          </Typography>
        </Paper>
      </Grid>
    </React.Fragment>
  );
}

TotalPayments.propTypes = {
  title: PropTypes.string,
  amount: PropTypes.number,
  money: PropTypes.bool,
};
