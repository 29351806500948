import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import Login from "./pages/Login";
import ManualSimulation from "./pages/ManualSimulation";
import IntegratedSimulation from "./pages/IntegratedSimulation";
import PaymentList from "./pages/PaymentList";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import IssuePayment from "./pages/IssuePayment";
import SignUp from "./pages/Signup";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import Dashboard from "./pages/Dashboard";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/cadastrar" element={<SignUp />} />
          <Route path="/pagamentos" element={<PaymentList />} />
          <Route path="/emitir-pagamento" element={<IssuePayment />} />
          <Route path="/simulacao-manual" element={<ManualSimulation />} />
          <Route
            path="/simulacao-integrada"
            element={<IntegratedSimulation />}
          />
        </Routes>
      </LocalizationProvider>
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
