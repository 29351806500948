import * as React from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import { toReal } from "../helpers/formatters";
import Typography from "@mui/material/Typography";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import Divider from "@mui/material/Divider";

function SimulationResponse(props) {
  const { response } = props;

  if (typeof response === "undefined" || Object.keys(response).length === 0) {
    return <></>;
  }

  return (
    <Box py={5}>
      <Typography>Resultado</Typography>
      <Divider sx={{ mt: 3, mb: 3 }} />
      <Title>Disponível para saque: </Title>
      <Transferable>{toReal(response.transferable)}</Transferable>
      <Box py={5}>
        <Grid container spacing={5}>
          <Grid item>
            <SubTitle>Total:</SubTitle>
            <Money>{toReal(response.available)}</Money>
          </Grid>
          <Grid item>
            <SubTitle>Taxas:</SubTitle>
            <Money>{toReal(response.fees)}</Money>
          </Grid>
          <Grid item>
            <SubTitle>IOF:</SubTitle>
            <Money>{toReal(response.iof)}</Money>
          </Grid>
          <Grid item>
            <SubTitle>TAC:</SubTitle>
            <Money>{toReal(response.tac)}</Money>
          </Grid>
        </Grid>
      </Box>
      <Box py={5}>
        <Grid container spacing={5}>
          <Grid item>
            <Label>
              Equivalente a 60x de: {toReal(response.available / 60)}
            </Label>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}

const Title = styled(Typography)(({ theme }) => ({
  fontWeight: "bolder",
  color: "#666",
  fontSize: "1.1em",
}));

const Transferable = styled(Typography)(({ theme }) => ({
  fontWeight: "bolder",
  color: "#0e7700",
  fontSize: "1.25em",
}));

const SubTitle = styled(Typography)(({ theme }) => ({
  color: "#999",
  fontSize: "1em",
}));

const Label = styled(Typography)(({ theme }) => ({
  color: "#999",
  fontSize: "1em",
}));

const Money = styled(Typography)(({ theme }) => ({
  fontWeight: "bolder",
  color: "#365035",
  fontSize: "1em",
}));

export const ResponseType = {
  transferable: PropTypes.number.isRequired,
  available: PropTypes.number.isRequired,
  fees: PropTypes.number.isRequired,
  iof: PropTypes.number.isRequired,
};

SimulationResponse.propTypes = {
  response: PropTypes.oneOf([null, ResponseType]),
};

export default SimulationResponse;
